.projects-section {
    background-color: #1c1c1c;
    color: white;
  }
  
  .section-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 40px;
  }
  
  .highlight {
    color: #ff66ff;
  }
  
  .project-card {
    background-color: #2c2c2c;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
  }
  
  .project-img {
    border-radius: 10px;
    width: 320px;
    height: 180px;
  }
  
  .project-title {
    margin-top: 15px;
    color: white;
  }
  